import React from 'react'
import './Stylesheet/Section-1.css'
import './Stylesheet/Section-2.css'
import './Stylesheet/Section-3.css'
import './Stylesheet/Section-4.css'

import data from './Data'
import { NavBar } from './NavbarComp/NavBar'

import logo from './imgfolder/logo.webp'
import dummy from './imgfolder/dummy-1.png'
import dummy1 from './imgfolder/dummy-2.png'
import dummy2 from './imgfolder/dummy-3.png'
import dummy3 from './imgfolder/dummy-4.png'

export const HomePage = () => {

    // const WhatsappLink = "https://wa.me/+919359084742?text=%20Hi%20Mr%20Admin";
    const appDownloadLink = require("./imgfolder/LaxmiGames.apk");

    return (
        <>
            <>
                {/* Section 0 Nav bar here. */}
                <div className='section-0'>
                    <NavBar />
                </div>
            </>
            <>
                <div className="container-fluid mt-2">
                    {/* Section 1 here. */}
                    <div className='section-1 container-xl'>
                        {/* Title section */}
                        <div className='col-12 d-flex align-items-center justify-content-between'>
                            <h1 style={{ color: "#97154d", fontWeight: "700" }}>Laxmi Games</h1>
                            {/* <a href={WhatsappLink}><img src="https://img.icons8.com/color/40/000000/whatsapp--v1.png" alt='..'/></a> */}
                        </div>

                        {/* Title section image and name */}
                        <div className='section1innerside2 col-12'>
                            <div className='col-1 card-img' style={{ width: "auto" }}>
                                <img className="img" src={logo} style={{ height: "4rem" }} alt='...' />
                            </div>
                            <div className='col-11' style={{ paddingLeft: "10px", width: "auto" }}>
                                <h5 style={{ fontWeight: "700" }}>Laxmi Games</h5>
                                <p>Laxmi Games</p>
                                {/* <div className='section1innerside2-ptags'>
                                    <p style={{ fontSize: "xx-small" }} className='text-nowrap border border-secondary rounded-pill me-2 p-1'>Satta Games</p>
                                    <p style={{ fontSize: "xx-small" }} className='text-nowrap border border-secondary rounded-pill me-2 p-1'>Win Real Cash</p>
                                </div> */}
                            </div>
                        </div>

                        {/* Details Section */}
                        <div className='col-12'>
                            <div className='section-1-RatingSection mb-1'>
                                <div className='appinfoSection-1 col-2 pt-2'>
                                    <div><p><strong>4.9  <img className='iconImg' src="https://img.icons8.com/ios-glyphs/12/000000/star--v1.png" alt='...' /> </strong></p></div>
                                    <div><p>3.5K reviews</p></div>
                                </div>
                                <div className='h1tagStyle' />
                                <div className='appinfoSection-2 col-2 pt-2'>
                                    <div><p><img className='iconImg' src="https://img.icons8.com/ios-glyphs/18/000000/download--v1.png" alt='...' /></p></div>
                                    <div><p>9 MB</p></div>
                                </div>
                                <div className='h1tagStyle' />
                                <div className='appinfoSection-3 col-2 pt-2'>
                                    <div><p><strong>3.9+</strong></p></div>
                                    <div><p>4+ Rated</p></div>
                                </div>
                                <div className='h1tagStyle' />
                                <div className='appinfoSection-4 col-2 pt-2'>
                                    <div><p><strong>50K+</strong></p></div>
                                    <div><p>Downloads</p></div>
                                </div>
                            </div>

                            <div className='col-12 d-flex align-items-center mb-2'>
                                <a href={appDownloadLink} className="w-100" download="LaxmiGames">
                                    <button type="button" className="btn btn-success w-100"><strong>INSTALL</strong></button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr style={{ margin: "0px" }} />

                    {/* Section 2 here */}
                    <div className='section-2 container-fluid mt-2'>
                        <div className='carDiv d-flex'>
                            <div className='imgDiv col-2 card'>
                                <img src={dummy} className="rounded float-start infoimgstyle" alt="..." />
                            </div>
                            <div className='imgDiv col-2'>
                                <img src={dummy1} className="rounded float-start infoimgstyle" alt="..." />
                            </div>
                            <div className='imgDiv col-2'>
                                <img src={dummy2} className="rounded float-start infoimgstyle" alt="..." />
                            </div>
                            <div className='imgDiv col-2'>
                                <img src={dummy3} className="rounded float-start infoimgstyle" alt="..." />
                            </div>
                            <div className='imgDiv col-2'>
                                <img src={dummy} className="rounded float-start infoimgstyle" alt="..." />
                            </div>
                            <div className='imgDiv col-2'>
                                <img src={dummy1} className="rounded float-start infoimgstyle" alt="..." />
                            </div>
                            <div className='imgDiv col-2'>
                                <img src={dummy2} className="rounded float-start infoimgstyle" alt="..." />
                            </div>
                        </div>

                        <div className='section2InfoSection-2 mt-5 mb-3 container-xl'>
                            <div>
                                <h4 style={{ fontWeight: "600", color: "#4a4a4a" }}>About this page</h4>
                                <p>Play Cards games on India’s most loved real money entertainment platform. 10000+ players are playing every day. Choose your contest / table, small stakes or big stakes. Unlimited Real Cash to be won!</p>
                            </div>
                            <p className="blockquote mt-4 text-center" style={{ fontWeight: "600", fontSize: "1.05rem", color: "#39875F" }}>READ MORE</p>
                        </div>
                    </div>
                    <hr style={{ margin: "0px" }} />

                    {/* Section 3 here */}
                    <div className='section-3 container-xl mt-3 mb-2'>
                        <div className='innerTop-section3Topdiv'>

                            {/* Heading */}
                            <div>
                                <h4 style={{ fontWeight: "600", color: "#4a4a4a" }}>Ratings and reviews</h4>
                            </div>

                            {/* BodySection */}
                            <div className='mt-4'>
                                <div className='d-flex row'>
                                    <div className='col-4' style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                        {/* <h3>Rating And Review</h3> */}
                                        <div>
                                            <p style={{ fontSize: "3.03rem" }}>4.9</p>
                                            <div>
                                                <img src="https://img.icons8.com/material-rounded/10/228C22/star--v1.png" alt='...' />
                                                <img src="https://img.icons8.com/material-rounded/10/228C22/star--v1.png" alt='...' />
                                                <img src="https://img.icons8.com/material-rounded/10/228C22/star--v1.png" alt='...' />
                                                <img src="https://img.icons8.com/material-rounded/10/228C22/star--v1.png" alt='...' />
                                                <img src="https://img.icons8.com/material-rounded/10/228C22/star-half-empty.png" alt='...' />
                                            </div>
                                            <p style={{ fontSize: ".9rem" }}>3587</p>
                                        </div>
                                    </div>

                                    <div className='col-8 userRatingDiv d-flex align-items-center justify-content-center'>
                                        <div style={{ width: "100%" }}>
                                            <div className='d-flex align-items-center col-12 w-auto'>
                                                <h4 className='userRatingNumber-5'>5</h4>
                                                <div className='userRating-5'></div>
                                            </div>

                                            <div className='d-flex align-items-center col-12 w-auto'>
                                                <h4 className='userRatingNumber-5'>4</h4>
                                                <div className='userRating-4'></div>
                                            </div>

                                            <div className='d-flex align-items-center col-12 w-auto'>
                                                <h4 className='userRatingNumber-5'>3</h4>
                                                <div className='userRating-3'></div>
                                            </div>

                                            <div className='d-flex align-items-center col-12 w-auto'>
                                                <h4 className='userRatingNumber-5'>2</h4>
                                                <div className='userRating-2'></div>
                                            </div>

                                            <div className='d-flex align-items-center col-12 w-auto'>
                                                <h4 className='userRatingNumber-5'>1</h4>
                                                <div className='userRating-1'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex mt-5 align-itmes-center justify-content-around'>
                                    <div className='gamePlay-Section3' ><p style={{ fontSize: ".9rem" }}>Gameplay <strong>4.8 <img src="https://img.icons8.com/ios-glyphs/10/000000/star--v1.png" alt='...' /></strong></p></div>
                                    <div className='gamePlay-Section3' ><p style={{ fontSize: ".9rem" }}>Controls <strong>4.9 <img src="https://img.icons8.com/ios-glyphs/10/000000/star--v1.png" alt='...' /></strong></p></div>
                                    <div className='gamePlay-Section3' ><p style={{ fontSize: ".9rem" }}>Graphics <strong>4.8 <img src="https://img.icons8.com/ios-glyphs/10/000000/star--v1.png" alt='...' /></strong></p></div>
                                </div>
                            </div>
                            <hr />

                            {/* Footer Section */}
                            <div>
                                <h5>Most mentioned in reviews : </h5>
                                <div className='section1innerside2-ptags mb-2' style={{ height: "fit-content", fontSize: "small" }}>
                                    <span className='text-nowrap border border-secondary rounded-pill mb-3 me-2 p-1'>Fastest Withdrawals</span>
                                    <span className='text-nowrap border border-secondary rounded-pill mb-3 me-2 p-1'>Genuine Players</span>
                                    <span className='text-nowrap border border-secondary rounded-pill mb-3 me-2 p-1'>Addictive</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr style={{ margin: "0px" }} />

                    {/* Section 4  Comment */}
                    <div className='section-4 container-xl' style={{ fontSize: "small" }}>
                        {
                            data ? data.map((item, i) => {

                                let avterColorCode = Math.floor(Math.random() * 100000) + 199999;

                                let myStr = item.name
                                let firstWord = myStr.split(" ")[0]
                                var FirstLetter = firstWord.match(/\b(\w)/g)

                                return (
                                    <div className='mt-4 multirow' key={i}>
                                        <div className='commentDivsection'>
                                            <div className='userMessage'>
                                                <div className='d-flex align-items-center avtarAndName'>
                                                    <div className='avtarDiv' style={{ backgroundColor: "#" + avterColorCode }}><p>{FirstLetter}</p></div>
                                                    <p style={{ marginLeft: "5px" }}>{item.name}</p>
                                                </div>
                                                <div className='d-flex align-item-center'>
                                                    <p style={{ margin: "4px", display: "flex", alignItems: "center" }}>
                                                        <img src="https://img.icons8.com/material/15/000000/star--v1.png" alt='...' />
                                                        <img src="https://img.icons8.com/material/15/000000/star--v1.png" alt='...' />
                                                        <img src="https://img.icons8.com/material/15/000000/star--v1.png" alt='...' />
                                                        <img src="https://img.icons8.com/material/15/000000/star--v1.png" alt='...' />
                                                        <img src="https://img.icons8.com/material/15/000000/star--v1.png" alt='...' />
                                                        {/* <img src="https://img.icons8.com/material/15/CCCCCC/star--v1.png" alt='...' /> */}
                                                    </p>
                                                    <p style={{ margin: "4px" }}>{item.commentdate}</p>
                                                </div>
                                                <div>{item.comment}</div>
                                            </div>

                                            {
                                                item.ownereeplyer.replyer ?
                                                    <div className='ownerMessage mt-2'>
                                                        <div className='offset-1 col-11 p-2 ownerMessage-Innerside'>
                                                            <div className='ownerNameAndDate'>
                                                                <p style={{ fontWeight: "600" }}>{item.ownereeplyer.replyer}</p>
                                                                <p>{item.ownereeplyer.replyedate}</p>
                                                            </div>
                                                            <div className='mt-3'>{item.ownereeplyer.replaycomment}</div>
                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                            <hr />
                                        </div>
                                    </div>
                                )
                            }) : "NULL"
                        }
                    </div>
                </div>
            </>
        </>
    )
}