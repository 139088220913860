const Data = [
    {
        name : "Manoj Chandrawanshi",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Good",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Amarlal Jogi",
        rating : "5",
        commentdate : "09/10/2022",
        comment : "Good.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Mahadev Chavan",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super Application Badhiya application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Sahebrao Ghanand",
        rating : "5",
        commentdate : "08/19/2022",
        comment : "Super 1 number application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Ajit Kamble",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Badiya app he no 1 he.",
        ownereeplyer : {
            replyer : "Laxmi Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Laxman Shahapurkar",
        rating : "5",
        commentdate : "08/19/2022",
        comment : "Trusted super application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Chetan Chaudhari",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "No 1 application.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Pandurang Thigale",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Hi I like your application sir.",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Jaleshwar Kurre",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Nice 👍👍👍👍👍 Super app.",
        ownereeplyer : {
            replyer : "Laxmi Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Chotu Sidar",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super Application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Devidas Kulkarni",
        rating : "5",
        commentdate : "09/04/2022",
        comment : "Honest Trust",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Raju Thakor",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Number 1 Application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Jaipal Nain",
        rating : "5",
        commentdate : "26/09/2020",
        comment : "Best Online matka app in our India best best best best matka app",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Surendra Vishwakarma",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Very Good App",
        ownereeplyer : {
            replyer : "Laxmi Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Bibishan 77",
        rating : "5",
        commentdate : "08/01/2022",
        comment : "Super fast whithdraw system",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Anil Lodhi",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super se upper application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Sumit Sawant",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "World best game app",
        ownereeplyer : {
            replyer : "Laxmi Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Dattatray Kamble",
        rating : "5",
        commentdate : "08/20/2022",
        comment : "Very nice game sir",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Nayeem SK",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Very trusted app nice",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Ramu Mudhiraj",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super application Trusted Application",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Mahendra Madavi",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Very trusted application very good app",
        ownereeplyer : {
            replyer : "Laxmi Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
    {
        name : "Loin f gaming",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Super application very very good application app game all game",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Rahul Ghunki",
        rating : "5",
        commentdate : "09/19/2022",
        comment : "Nio 1 apllication Best app very nice app Sir",
        ownereeplyer : {
            replyer : "",
            replaycomment : "",
            replyedate : ""
        }
    },
    {
        name : "Kailash Rathod",
        rating : "5",
        commentdate : "09/11/2022",
        comment : "Nice app !!!!!!!!! Very good app Good Design!!! Admin reply very good very fast result update",
        ownereeplyer : {
            replyer : "Laxmi Games",
            replaycomment : "Thank Your sir",
            replyedate : "11/05/2022"
        }
    },
];

export default Data;