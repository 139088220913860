import React from 'react'
// import './navJS'
import './navCSS.css'

export const NavBar = () => {

    const appDownloadLink = require("../imgfolder/LaxmiGames.apk");

    return (
        <>
            <nav className="navbar navbar-dark" id='navbar'>
                <div className="container-fluid d-flex align-items-center justify-content-center">
                    <a className='w-100' href={appDownloadLink} download="LaxmiGames">
                        <button className='btn btn-success w-100'> Install </button>
                    </a>
                </div>
            </nav>
        </>
    )
}
